import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import DateSvg from '../../../static/svg/dateSvg';

const WelcomeBanner = () => {
  return (
    <div
      className="banner banner-style-5"
      style={{
        backgroundImage: "url('/images/home/welcome-banner-bg.jpg')",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xl-8">
            <div className="banner-content -welcome">
              <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                <h1 className="title">Exclusive Welcome Offer for New Customers</h1>
                <span className="subtitle">
                  Complete your first drawdown with us within the campaign period and receive&nbsp;
                  <strong>SGD50</strong>&nbsp;shopping vouchers
                </span>
                <div className="date-wrap pb--70">
                  {DateSvg({})}
                  <div>
                    <p>Campaign Period</p>
                    <p className="-bold">19 September - 19 October 2022</p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBanner;
