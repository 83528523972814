import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { Link } from 'gatsby';
import libphonenumber from 'google-libphonenumber';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { SuccessSvg, YesSvg } from '../../static/svg/invoiceSvgCom';
import WelcomeBanner from '../components/banner/WelcomeBanner';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Layout from '../components/layout';
import Seo from '../components/seo';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

interface IFormInputs {
  email?: string;
  first_name?: string;
  last_name?: string;
  organisation?: string;
  mobile_number?: string;
}

declare let grecaptcha: any;

const InvoicePaid = () => {
  // Invoice Paid Calculator
  const rate = (0.15 * 60) / 360;
  const [invoiceAmount, setInvoiceAmount] = useState(10000);
  const [phone, setPhone] = useState<intlTelInput.Plugin>();
  const [show, setShow] = useState(false);

  Yup.addMethod(Yup.string, 'phone', function (message) {
    return this.test({
      name: 'phone',
      exclusive: true,
      message: message || 'must be a phone number',
      test: async function (value) {
        if (!phone || !value) {
          return false;
        }
        try {
          const area = phone.getSelectedCountryData().iso2.toUpperCase();
          const number = phoneUtil.parse(value, area);
          return phoneUtil.isValidNumber(number);
        } catch (error) {}
        return false;
      },
    });
  });

  const schema = Yup.object().shape({
    organisation: Yup.string().required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    mobile_number: Yup.string().required('Required').phone('The phone number is invalid.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const mobileInput = document.querySelector('#mobile');
    if (mobileInput) {
      const phoneState = intlTelInput(mobileInput, {
        initialCountry: 'auto',
        geoIpLookup: (callback) => {
          axios
            .get<
              undefined,
              {
                data: {
                  country_code: string;
                };
              }
            >('https://ipapi.co/json/', {
              responseType: 'json',
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.country_code) {
                callback(resp.data.country_code.toLowerCase());
              } else callback('sg');
            });
        },
        preferredCountries: ['sg', 'cn', 'hk', 'us'],
      });
      setPhone(phoneState);
    }
  }, []);

  const onSubmit = (data: IFormInputs) => {
    if (!phone) {
      return;
    }

    const area = phone.getSelectedCountryData().dialCode;

    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute('6Le4KpEaAAAAACJw4liW38ANFo2whC6FjqdWhDdX', {
          action: 'submit',
        });
        const result = await axios.post(
          `${process.env.API_URL}/api/schedule-chats`,
          {
            ...data,
            token,
            mobile: `${area}${data.mobile_number}`,
            query_type: 'InvoicePaid+',
            full_name: `${data.first_name} ${data.last_name}`,
            question: 'Exclusive Welcome Offer for Savills Vendors',
          },
          {
            responseType: 'json',
          },
        );

        if (result && result.data && result.data.status) {
          setShow(true);
        }
      } catch (error) {}

      // 失败弹窗
    });
  };

  return (
    <Layout>
      <Seo
        title="Exclusive Welcome Offer | Sign up today"
        description="Receive SGD50 shopping voucher when you finance with us"
      />
      <main className="main-wrapper">
        <Header />
        <WelcomeBanner />

        <div className="section ">
          <div className="container">
            <div className="welcome-wrap">
              <p className="-breadcrumb">Three easy steps to receive this offer</p>
              <div className="receive-offer-contact">
                <div className="contact-form">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="organisation">Company name</label>
                    <input
                      {...register('organisation')}
                      id="organisation"
                      name="organisation"
                      placeholder="Enter your company name"
                    />
                    <p>{errors.organisation?.message}</p>
                    <label htmlFor="email">Email address</label>
                    <input
                      {...register('email')}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email address"
                    />
                    <p>{errors.email?.message}</p>
                    <label htmlFor="mobile">Mobile number</label>
                    <input
                      {...register('mobile_number')}
                      id="mobile"
                      name="mobile_number"
                      placeholder="Mobile phone"
                    />
                    <p>{errors.mobile_number?.message}</p>
                    <label htmlFor="first_name">First name</label>
                    <input
                      {...register('first_name')}
                      id="firstname"
                      // name="full_name"
                      placeholder="Enter your first name"
                    />
                    <p>{errors.first_name?.message}</p>
                    <label htmlFor="last_name">Last name</label>
                    <input
                      {...register('last_name')}
                      id="lastname"
                      // name="full_name"
                      placeholder="Enter your last name"
                    />
                    <p>{errors.last_name?.message}</p>
                    <div className="btn-wrap">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                <div className="row content-3">
                  <div className="col">
                    <div className="circle">1</div>
                    <div>
                      <p className="-description">Fill in your interest via contact form</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="circle">2</div>
                    <div>
                      <p className="-description">
                        Complete your application and assessment through our representative
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="circle">3</div>
                    <div>
                      <p className="-description">
                        Successfully submit valid invoice to
                        <img className="light-mode" src="/images/invoicepaid-img.png" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <p className="-breadcrumb">About InvoicePaid+</p>
              <div className="content-1">
                <div>
                  <img
                    className="light-mode"
                    src="/images/invoicepaid+-svg.png"
                    style={{ width: 206 }}
                  />
                </div>
                <div>
                  <p className="-title">
                    InvoicePaid+ is a cash management tool designed with industry partners and
                    experts to help companies better manage their cash, increase business certainty
                    and improve their returns.
                  </p>
                  <Link className="banco-btn btn-fill-primary btn-fluid" to="/invoice-paid">
                    Read more about InvoicePaid+
                  </Link>
                </div>
              </div>

              <p className="-breadcrumb">Take control of your cash</p>
              <div className="row content-2">
                <div className="card col-4">
                  <div>
                    <img className="light-mode" src="/images/easy-svg.png" style={{ width: 90 }} />
                  </div>
                  <div>
                    <p className="-title">Easy</p>
                    <p className="-description">
                      Sign up today within <span>10mins</span> with minimal documents to provide
                    </p>
                  </div>
                </div>
                <div className="card col-4">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/honest-svg.png"
                      style={{ width: 106 }}
                    />
                  </div>
                  <div>
                    <p className="-title">Honest</p>
                    <p className="-description">
                      All-in convenience fee with InvoicePaid+.&nbsp;<span>No hidden charges</span>
                    </p>
                  </div>
                </div>
                <div className="card col-4">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/flexible-svg.png"
                      style={{ width: 110 }}
                    />
                  </div>
                  <div>
                    <p className="-title">Flexible</p>
                    <p className="-description">
                      <span>Add and manage</span> your new or existing invoices as and when you
                      need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-padding">
          <div className="container">
            <div className="row invoice-calculator">
              <div className="col-lg-8 calculator">
                <h3>Adjust the sliders to see how much you can get upfront and the estimated</h3>
                <h4>Convenience fee</h4>
                <label htmlFor="invoiceAmount" className="form-label">
                  Invoice Amount (SGD)
                  <span>${new BigNumber(invoiceAmount).toFormat()}</span>
                </label>

                <Slider
                  className="form-range"
                  value={invoiceAmount}
                  onChange={(value) => setInvoiceAmount(Array.isArray(value) ? value[0] : value)}
                  min={0}
                  step={1000}
                  max={1000000}
                />

                <p className="description">
                  *The figures generated by this calculator are intended as a guide only, based on a
                  60 days payment terms and varies based on the information you provide. This shall
                  not, and is not intended to, constitute a commitment that rates offered to you
                  will necessarily be better than those generated by the calculator, and, in
                  particular, is not a commitment to provide any financing. Rates will be determined
                  according to, among other things, our analysis of your risk profile. Any financing
                  is subject to such terms and conditions, disclaimers and lending criteria as may
                  be set by Invoicepaid+ from time to time.
                </p>
              </div>
              <div className="col-lg-4 result">
                <h3>Calculation</h3>
                <div className="result-row">
                  <div className="item">How much you could get now</div>
                  <div className="value">
                    $
                    {new BigNumber(0.8)
                      .times(1 - rate)
                      .times(invoiceAmount)
                      .toFormat()}
                  </div>
                </div>
                <div className="result-row">
                  <div className="item">Plus, when invoices paid</div>
                  <div className="value">
                    ${new BigNumber(0.2).times(invoiceAmount).toFormat(2)}
                  </div>
                </div>
                <div className="result-fee-row">
                  <div className="item">Your estimated convenience fee</div>
                  <div className="value">
                    ${new BigNumber(rate).times(invoiceAmount).times(0.8).toFormat(2)}
                  </div>
                </div>
                <div className="row row-8">
                  <button
                    className="banco-btn btn-fill-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#eligibilityForm"
                  >
                    Get Started
                  </button>
                </div>
              </div>

              <p className="description des-mobile">
                *The figures generated by this calculator are intended as a guide only, based on a
                60 days payment terms and varies based on the information you provide. This shall
                not, and is not intended to, constitute a commitment that rates offered to you will
                necessarily be better than those generated by the calculator, and, in particular, is
                not a commitment to provide any financing. Rates will be determined according to,
                among other things, our analysis of your risk profile. Any financing is subject to
                such terms and conditions, disclaimers and lending criteria as may be set by
                InvoicePaid+ from time to time.
              </p>
            </div>
          </div>
        </div>

        <div className="question-box">
          <p>Ready to sign up for InvoicePaid+?</p>
          <Link className="banco-btn btn-fill-primary btn-fluid" to="/contact">
            {YesSvg({ width: 69 })}I want to apply now
          </Link>
        </div>

        <div className="section notes-box">
          <div className="container">
            <p className="title">Important Notes</p>
            <ol>
              <li>
                InvoicePaid+ Welcome Offer campaign is open exclusively to new applications
                (customers who do not have an account with banco) who register for a new account and
                finance an invoice within the campaign period.
              </li>
              <li>
                Each eligible customer is entitled to receive a <span>SGD50</span> shopping vouchers
                from FairPrice.
              </li>
              <li>
                The campaign period refers to the period from{' '}
                <span>19 September - 19 October 2022</span>.
              </li>
              <li>
                Banco reserves the right to remedy, including cancellation of accounts or
                applications, if fraud, tampering or violation of these terms of banco’s terms and
                conditions, or if technical errors are suspected.
              </li>
              <li>
                Eligible customers consent to banco collection and use of personal data according to
                banco <Link to="/privacy-policy">Privacy Policy</Link>.
              </li>
            </ol>
          </div>
        </div>

        <Modal className="contact-modal" show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <button onClick={() => setShow(false)}>
              <img src="/images/close-img.png" alt="" />
            </button>
          </Modal.Header>
          <Modal.Body>
            {SuccessSvg({})}
            <p>We have received your message.</p>
            <p>We’ll get back to you as soon as possible.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShow(false)}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </main>
    </Layout>
  );
};

export default InvoicePaid;
